// Import the functions you need from the SDKs you need
/* eslint-disable no-unused-vars */
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const env = process.env.REACT_APP_ENV ?? 'DEV';

export const CONTINOUS_URL =
  env === 'DEV'
    ? 'https://restaurant.tchopme.com'
    : 'https://tchop-me-restaurant-web-dev.web.app/';

const firebaseConfig = {
  apiKey: process.env?.[`REACT_APP_API_KEY_${env}`],
  authDomain: process.env?.[`REACT_APP_AUTH_DOMAIN_${env}`],
  projectId: process.env?.[`REACT_APP_PROJECT_ID_${env}`],
  storageBucket: process.env?.[`REACT_APP_STORAGE_BUCKET_${env}`],
  messagingSenderId: process.env?.[`REACT_APP_MESSAGING_SENDER_ID_${env}`],
  appId: process.env?.[`REACT_APP_APP_ID_${env}`],
  measurementId: process.env?.[`REACT_APP_MEASUREMENT_ID_${env}`]
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const projectId = app.options.projectId;
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);
export const storage = getStorage(app);
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCNr-z_noxuxcXhOZk5q1akPyyFoOL-wEw';

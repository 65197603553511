// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const NotifDot = ({
  size,
  color = '#344051'
}: {
  color?: string;
  size?: string | number;
}) => {
  return (
    <Box
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: '50%',
        mx: 1,
        bgcolor: color
      }}
    />
  );
};

export default NotifDot;

import dayjs from 'dayjs';
import { Order } from '../data.def';
import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatISO,
  parseISO
} from 'date-fns';
import { fr } from 'date-fns/locale';

export const APP_VERSION = 0;

export const COLLECTIONS_NAMES = {
  REVIEWS: 'REVIEWS',
  DRIVERS: 'DRIVERS',
  ORDERS: 'ORDERS',
  RESTAURANTS: 'RESTAURANTS',
  CUSTOMERS: 'CUSTOMERS',
  REPORTS: 'REPORTS',
  CATEGORIES: 'CATEGORIES',
  DISHES_BEVERAGE: 'DISHES_BEVERAGE',
  TAG: 'TAGS',
  SIDES: 'SIDES',
  USERS: 'USERS',
  RESTO_USERS: 'RESTO_USERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  paymentMethods: 'paymentMethods'
};

export const STORAGE_DIRECTORY = {
  MENU_IMAGES_DIRECTORY: 'MENU_IMAGES',
  AUTH_IMAGES: 'AUTH_IMAGES'
};
export const ORDERS_STATUS = {
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  NEW: 'NEW',
  READY: 'READY',
  WAITING_DELIVERY: 'WAITING_DELIVERY',
  WAITING_DRIVER: 'WAITING_DRIVER',
  PREPARING: 'PREPARING',
  ONROUTE: 'ON_ROUTE',
  VALIDATED_BY_RESTO: 'VALIDATED_BY_RESTO'
};

export const PAYMENT_STATUS = {
  PAID: 'PAID'
};

export const MEAL_TYPES = {
  MAIN: 'MEAL',
  SECONDARY: 'SECONDARY'
};

export const DATES = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const CURRENCIES = ['XAF', 'USD', 'EUR'];

export const DAYORDER = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const getAmountInCart = (item: any) => {
  //console.log("**************getAmountInCart item", item)
  return (
    Number(item.quantity) *
    (Number(item.price) +
      Number(item.complementPrice) +
      item.extras?.reduce(
        (acc: number, item: any) =>
          acc + Number(item.quantity) * Number(item.price),
        0
      ))
  );
};

export const sanitizeOrders = (orders: Order[]): Order[] => {
  const sanitizedOrders: Order[] = [];

  orders.forEach((order: Order) => {
    const deliveryFee = Number(order?.deliveryFee ?? 0);
    const manageMentFee = Number(order?.mgnFee ?? 0);
    const codeValue = order?.promoCodeValue ?? 0;

    const hotDealTotalInfos = order?.items
      .filter((item) => item.isHotDeal && !item.cancelled)
      .reduce(
        (acc: any, curr: any) => {
          acc.quantity += curr.quantity;
          acc.subtotal += getAmountInCart(curr);
          return acc;
        },
        { subtotal: 0, quantity: 0 }
      );

    const noHotDealTotalInfos = order?.items
      .filter((item) => !item.isHotDeal && !item.cancelled)
      .reduce(
        (acc: any, curr: any) => {
          acc.quantity += curr.quantity;
          acc.subtotal += getAmountInCart(curr);
          return acc;
        },
        { subtotal: manageMentFee + deliveryFee, quantity: 0 }
      );

    const realTotalWithoutDiscount =
      hotDealTotalInfos?.subtotal + noHotDealTotalInfos?.subtotal;

    let discount = 0;
    discount = noHotDealTotalInfos?.subtotal * codeValue * 0.01;

    const fullTotal = {
      subtotal:
        noHotDealTotalInfos?.quantity > 0
          ? noHotDealTotalInfos?.subtotal -
            discount +
            hotDealTotalInfos?.subtotal
          : noHotDealTotalInfos?.subtotal + hotDealTotalInfos?.subtotal,
      quantity: noHotDealTotalInfos?.quantity + hotDealTotalInfos?.quantity
    };

    const snitizedOrder = {
      ...order,
      total: order?.promoCodeID ? fullTotal?.subtotal : realTotalWithoutDiscount
    };

    sanitizedOrders.push(snitizedOrder);
  });

  return sanitizedOrders;
};

export const postBrowserNotification = (
  title: string,
  notiVBody: string,
  notivIcon: string
) => {
  new Notification(title, { body: notiVBody, icon: notivIcon });
};

export function getDiffText(
  date: Date | number | string | null | undefined,
  loc = 'en'
): string {
  if (!date) {
    return '';
  }
  const iso_date = formatISO(new Date(dayjs(date).toDate()));
  const diff = dayjs().diff(dayjs(date), 'days');

  return `${formatDistanceToNow(parseISO(iso_date), {
    addSuffix: true,
    locale: loc === 'fr' ? fr : undefined
  })}`;
}

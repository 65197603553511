// third-party
import { combineReducers, configureStore, AnyAction } from '@reduxjs/toolkit';
import uiReducer from './ui';
import authReducer from './auth';
import feedbackReducer from './feedback';
import driverReducer from './driver';
import orderReducer from './orders';
import customerReducer from './customer';
import categoryReducer from './categorySlice';
import tagReducer from './tagSlice';
import menuReducer from './menuSlice';
import sidesReducer from './sidesSlice';
import { initialState as uiState } from './ui';
import localforage from 'localforage';
import { persistReducer, persistStore } from 'redux-persist';
import api from '../api';
import notificationsReducer, {
  initialState as notifInit
} from './notificationSlice';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: 'tchopmeresto',
  storage: localforage,
  blacklist: ['ui']
};

const reducers = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  feedback: feedbackReducer,
  driver: driverReducer,
  order: orderReducer,
  customer: customerReducer,
  category: categoryReducer,
  tag: tagReducer,
  sides: sidesReducer,
  menu: menuReducer,
  notifications: notificationsReducer,
  [api.reducerPath]: api.reducer
});

const initialState: Record<string, any> = {
  ui: {
    ...uiState
  },
  auth: {
    user: null,
    token: '',
    userData: null,
    restaurant: null,
    paymentMethod: {}
  },
  feedback: {
    restaurant: {},
    meals: {},
    couriers: {}
  },
  driver: {
    driver: {},
    idList: []
  },
  order: {
    data: {},
    history: {}
  },
  customer: {
    customer: {},
    userIdList: []
  },
  category: {},
  sides: {},
  tag: {},
  menu: {},
  notifications: notifInit
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'USER_LOGGED_OUT') {
    return { ...initialState };
  }
  return reducers(state, action);
};
const persistReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistReducers,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootStateReturn = () => RootState;
// eslint-disable-next-line no-unused-vars
export declare type Callback = (...args: any[]) => void;

export default persistStore(store);

/* eslint-disable no-unused-vars */
import { AppDispatch, RootState } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { firestore, remoteConfig } from '../firebase';
import { listCategories } from '../store/categorySlice';
import { addAppconfig } from '../store/ui';
import { capitalizeFirstLetter } from '../utils/stringUtils';
import i18n from '../i18n-config';
import { CategoryItem } from '../data.def';
import { useSelector } from 'react-redux';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { COLLECTIONS_NAMES } from '../utils/constant';
import { fromFirebaseToJs } from '../utils/dateutils';
import { initNotif } from '../store/notificationSlice';

remoteConfig.settings.minimumFetchIntervalMillis = 1000;

export default function useInit() {
  const dispatch: AppDispatch = useDispatch();
  const lang = useSelector((state: RootState) => state.ui.lang.systemLanguage);

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const configs = getValue(remoteConfig, 'app_config');
        const app_config = JSON.parse(configs.asString());
        const result: CategoryItem[] = [];

        const menu_categories = app_config.menu_categories;

        for (let i = 0; i < menu_categories.length; i++) {
          const catetegories = menu_categories[i].category;
          for (let j = 0; j < catetegories.length; j++) {
            const item: CategoryItem = {
              id: catetegories[j].id,
              key: capitalizeFirstLetter(menu_categories[i]?.title),
              title:
                lang === 'fr'
                  ? capitalizeFirstLetter(catetegories[j].name?.fr)
                  : capitalizeFirstLetter(catetegories[j].name?.en),
              en: catetegories[j].name?.en,
              fr: catetegories[j].name?.fr
            };

            result.push(item);
          }
        }
        dispatch(listCategories(result));
        dispatch(addAppconfig(app_config));
      })
      .catch((err) => {
        console.log('fetch remote config error', err);
      });
  }, [dispatch, lang]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, COLLECTIONS_NAMES.NOTIFICATIONS)),
      (querySnapshot) => {
        const items: any[] = [];
        querySnapshot.forEach((doc) => {
          const notif = doc.data();
          items.push({
            ...notif,
            createdAt: fromFirebaseToJs(notif?.createdAt),
            updatedAt: fromFirebaseToJs(notif?.updatedAt)
          });
        });

        console.log('new notifications :', items.length);
        dispatch(initNotif(items));
      }
    );

    return () => unsubscribe();
  }, [dispatch]);

  return {};
}

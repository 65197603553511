/* eslint-disable no-invalid-regexp */
/* eslint-disable no-unused-vars */
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { SxProps, useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  ListItem,
  Button,
  Stack,
  Menu,
  MenuItem
} from '@mui/material';

// assets
import useGetColors from '../../../../hooks/useGetColor';
import NotificationIcon from '../../../../assets/icons/NotificationIcon';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import dayjs from 'dayjs';
import { fromFirebaseToJs } from '../../../../utils/dateutils';
import { ADMIN_NOTIFICATIONS_STATUS, Restaurant } from '../../../../data.def';
import {
  getDiffText,
  postBrowserNotification
} from '../../../../utils/constant';
import soundUrl from '../../../../assets/raw/resto_notification_2.mp3';
import { t } from 'i18next';
import useLocale from '../../../../hooks/useLocale';
import useAuth from '../../../../hooks/useAuth';
import NotifDot from '../../../../components/NotifDot';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import {
  CustomerCancelICON,
  DriverCancelICON,
  RestauCancelICON
} from '../../../../assets/icons';
import { markNotificationRead } from '../../../../store/auth';
import { useDispatch } from 'react-redux';
import { activeItem } from 'store/ui';
import { resto_notif } from 'routes/MainRoutes';
import { useNavigate } from 'react-router-dom';
import nagativeSound from '../../../../assets/raw/nagative_notiv.mp3';
const notificationSound = new Audio(soundUrl);
const nagativeNotificationSound = new Audio(nagativeSound);

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

function MettreEnSurbrillance({
  sentence,
  regrex1,
  regrex2,
  word1,
  word2,
  cancelledText
}: {
  sentence: string;
  regrex1: string;
  regrex2: string;
  word1: string;
  word2: string;
  cancelledText?: string;
}) {
  // Créer une expression régulière avec le mot recherché
  let regex1 = new RegExp(regrex1, 'gi');
  let regex2 = new RegExp(regrex2, 'gi');
  let regex3 = new RegExp('#cancelled', 'gi');

  // Remplacer toutes les occurrences du mot par une version surbrillée
  let resultat1 = sentence.replace(
    regex1,
    `<span style="fontWeight:'700' ;color:#333333 ; font-size:16px">${capitalizeFirstLetter(
      word1
    )}</span>`
  );

  let resultat2 = resultat1.replace(
    regex2,
    `<span style="fontWeight:'700' ;color:#333333 ; font-size:16px">${capitalizeFirstLetter(
      word2
    )}</span>`
  );

  let resultat3 = resultat2.replace(
    regex3,
    `<span style="color:#EB5757 ; font-size:16px">${cancelledText}</span>`
  );

  // Rendre le résultat dans le DOM
  return (
    <div
      style={{
        maxWidth: '320px',
        textWrap: 'wrap',
        color: '#656565',
        fontSize: '14px'
      }}
      dangerouslySetInnerHTML={{ __html: resultat3 }}
    />
  );
}

const render_text: any = {
  [ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_CUSTOMER]: (noti: any) => (
    <MettreEnSurbrillance
      sentence={t('cancelled_by_order')}
      regrex1="customer_name"
      regrex2="restaurant_name"
      word1={noti?.customer?.name}
      word2={noti?.restaurant?.restaurantName}
      cancelledText={t('cancelled')}
    />
  ),

  [ADMIN_NOTIFICATIONS_STATUS.NEW_ORDER]: (noti: any) => (
    <MettreEnSurbrillance
      sentence={t('new_order')}
      regrex1="customer_name"
      regrex2="restaurant_name"
      word1={noti?.customer?.name}
      word2={noti?.restaurant?.restaurantName}
      cancelledText={t('new_text')}
    />
  )
};

const icons = {
  [ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_CUSTOMER]: <CustomerCancelICON />,
  [ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_RESTO]: <RestauCancelICON />,
  [ADMIN_NOTIFICATIONS_STATUS.CANCELLED_DY_DRIVER]: <DriverCancelICON />,
  [ADMIN_NOTIFICATIONS_STATUS.CANCELLED_MEAL]: <RestauCancelICON />,
  [ADMIN_NOTIFICATIONS_STATUS.NEW_ORDER]: <CustomerCancelICON />
};

export const AdminNotifItem = ({
  el,
  textStyle
}: {
  el: any;
  textStyle?: SxProps;
}) => {
  const { restaurant } = useAuth();
  const { local } = useLocale();

  const show = useMemo(() => {
    const last_read = dayjs(fromFirebaseToJs(restaurant?.lastReadNotification));
    const publish_date = dayjs(fromFirebaseToJs(el.createdAt));
    return last_read.isBefore(publish_date);
  }, [el.createdAt, restaurant?.lastReadNotification]);

  return (
    <ListItem
      secondaryAction={show ? <NotifDot color="warning.main" /> : null}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={icons[el?.status]}
        >
          <Avatar
            sx={{ height: 50, width: 50 }}
            alt={el?.customer?.name}
            src={
              el?.type === ADMIN_NOTIFICATIONS_STATUS.DELETE_ACCOUNT
                ? el?.user?.photoURL ?? el?.user?.logoURL
                : el?.status ===
                  ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_CUSTOMER
                ? el?.customer?.image
                : el?.restaurant?.image
            }
          />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={render_text[el?.status]?.(el) ?? ''}
        secondary={getDiffText(el?.createdAt, local)}
        sx={{ ...textStyle }}
      />
    </ListItem>
  );
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const dispatch: any = useDispatch();
  const user: Restaurant = useSelector(
    (state: RootState) => state.auth.restaurant
  );
  const notifications = useSelector(
    (state: RootState) => state.notifications.data
  );

  const nav = useNavigate();
  const theme: any = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const colors = useGetColors();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(markNotificationRead());
  };

  const notifList = useMemo(
    () =>
      Object.values(notifications || {})
        .filter((noti: any) => {
          const last_read = dayjs(fromFirebaseToJs(user?.lastReadNotification));
          const publish_date = dayjs(fromFirebaseToJs(noti.createdAt));
          return (
            noti?.type === 'ADMIN' &&
            noti?.restaurant?.id === user?.id &&
            last_read.isBefore(publish_date) &&
            (noti?.status === ADMIN_NOTIFICATIONS_STATUS.NEW_ORDER ||
              noti?.status === ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_CUSTOMER)
          );
        })
        .sort(
          (a: any, b: any) =>
            Number(fromFirebaseToJs(b?.createdAt)) -
            Number(fromFirebaseToJs(a?.createdAt))
        ),
    [notifications, user?.lastReadNotification]
  );

  const see_all = () => {
    dispatch(activeItem({ openItem: [resto_notif.id] }));
    nav('resto_notifications');
    handleClose();
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  const [postedNotiv, setPostedNotiv] = useState<string[]>([]);
  useEffect(() => {
    notifList.forEach((notification: any) => {
      if (!postedNotiv.find((notiv) => notiv === notification.id)) {
        let title = '';
        let body = '';
        let icon = '';

        if (
          notification.status ===
          ADMIN_NOTIFICATIONS_STATUS.CANCELLED_BY_CUSTOMER
        ) {
          title = notification?.customer?.name + ' ' + t('cancelled_an_order');
          body = t('cancelled_by_order')
            .replace('#cancelled', t('cancelled'))
            .replace('customer_name', notification?.customer?.name ?? '');
          icon = notification?.customer?.image;
        }

        if (notification.status === ADMIN_NOTIFICATIONS_STATUS.NEW_ORDER) {
          title = t('notiv_new_order');
          body = t('new_order').replace(
            'customer_name',
            notification?.customer?.name ?? ''
          );
          icon = notification?.customer?.image;
        }

        postBrowserNotification(title, body, icon);
        if (notification.status === ADMIN_NOTIFICATIONS_STATUS.NEW_ORDER) {
          notificationSound.play().catch((error) => {
            console.log('could not play notification sound');
          });
        } else {
          nagativeNotificationSound.play().catch((error) => {
            console.log('could not play notification sound');
          });
        }

        setPostedNotiv((prev) => {
          return [...prev, notification.id];
        });
        console.log('posted notification');
      }
    });
  }, [notifList]);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        id="notification-menu"
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Badge
          badgeContent={notifList?.length.toString() ?? '0'}
          color="primary"
        >
          <NotificationIcon />
        </Badge>
      </IconButton>

      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification-menu'
        }}
        PaperProps={{
          sx: {
            overflowY: 'auto',
            padding: '',
            width: 400,
            maxHeight: 400,
            pb: 3,
            filter:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          pt={1}
          sx={{ position: 'sticky' }}
        >
          <Typography variant="h2" color="primary">
            {capitalizeFirstLetter(t('notifications'))}
          </Typography>
          <Button onClick={see_all} color="warning">
            {t('see_all')}
          </Button>
        </Stack>
        {notifList.length ? (
          notifList?.slice(0, 3).map((val: any, i) => (
            <MenuItem
              sx={{
                borderBottom: '1px solid' + (i !== 2 ? '#E6E6E6' : '#FFF')
              }}
              onClick={see_all}
              key={val?.id}
            >
              <AdminNotifItem el={val} />
            </MenuItem>
          ))
        ) : (
          <Typography sx={{ p: 2 }} textAlign="center">
            {' '}
            {t('no_notification')}{' '}
          </Typography>
        )}
      </Menu>
    </Box>
  );
};

export default Notification;

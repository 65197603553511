import { createSlice } from '@reduxjs/toolkit';

export interface Campaign {
  id?: string;
  userID?: string;
  title?: string;
  content?: string;
  legalText?: string;
  photoUrl?: string;
  isActive?: boolean;
  updateAt?: Date;
  createdAt?: Date;
  endtAt?: Date;
}

export const initialState: any = {
  data: {},
  usage: {},
  loading: false
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    initNotif(state, { payload }) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item.id] = item;
      });

      state.data = items;
      return state;
    },
    init_admin_notifications(state, { payload }) {
      state.admin_notifications = payload;
      return state;
    },
    addNotif(state, { payload }) {
      state.data[payload.id] = payload;
      return state;
    },
    updateNotif(state, { payload }) {
      state.data[payload.id] = {
        ...state.data[payload.id],
        ...payload
      };
      return state;
    }
  }
});

export default notificationSlice.reducer;

export const { initNotif, addNotif, updateNotif, init_admin_notifications } =
  notificationSlice.actions;

// types
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import { auth, firestore } from '../firebase';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { COLLECTIONS_NAMES, STORAGE_DIRECTORY } from '../utils/constant';
import { authIsLoaded, creatinRestaurant } from './ui';
import { v4 } from 'uuid';
import { signOut } from 'firebase/auth';
import { uploadImage } from '../utils/imageUtils';
import { notifyError } from '../utils/notify';
import i18n from '../i18n-config';
import { KeyboardReturnRounded } from '@mui/icons-material';

export interface Restaurant {
  id?: string;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
  userID?: string;
  description?: string;
  email?: string;
  banners?: any[];
  phoneNumber?: any;
  hours?: {
    monday: { close: boolean; time: string[] };
    tuesday: { close: boolean; time: string[] };
    wednesday: { close: boolean; time: string[] };
    thursday: { close: boolean; time: string[] };
    friday: { close: boolean; time: string[] };
    saturday: { close: boolean; time: string[] };
    sunday: { close: boolean; time: string[] };
  };
  tags?: string[];
  placeInfo?: string;
  logoURL?: string;
  location: {
    address?: string;
    createdAt?: Date;
    updatedAt?: Date;
    latitude?: number;
    longitude?: number;
  };
  rating?: any;
}
const localUser = sessionStorage.getItem('user');
// initial state
const initialState = {
  user: localUser,
  token: '',
  userData: null,
  restaurant: null,
  paymentMethod: {}
} as any;

// ==============================|| SLICE - MENU ||============================== //

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth(state, { payload }) {
      state.userData = payload.user;
      state.restaurant = payload.restaurant;
      state.paymentMethod = payload.paymentMethod;
      return state;
    },
    updateRestaurant(state, { payload }) {
      state.restaurant = payload;
      return state;
    },
    initPayment(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.documentId] = el;
      }
      state.paymentMethod = temp;
      return state;
    },
    createPayment: (state, action) => {
      const payload = action.payload;
      return { ...state, [payload.documentId]: payload };
    },
    deletePayment: (state, action) => {
      const payload = action.payload;
      delete state.paymentMethod[payload.documentId];
      return state;
    },
    addUuid: (state, { payload }) => {
      state.user.uuid = payload;
    },

    updateLastRead: (state, { payload }) => {
      state.restaurant.lastReadNotification = payload.lastReadNotification;
      return state;
    }
  }
});

export default authSlice.reducer;

export const {
  initAuth,
  addUuid,
  updateRestaurant,
  initPayment,
  createPayment,
  deletePayment,
  updateLastRead
} = authSlice.actions;

export const getRestaurantDataAsync =
  ({
    UserID,
    callback,
    onError
  }: {
    UserID: string;
    callback?: () => void;
    onError?: (ev: any) => void;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(authIsLoaded(true));

      const userRef = doc(firestore, COLLECTIONS_NAMES.RESTO_USERS, UserID);

      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const restaurantRef = query(
          collection(firestore, COLLECTIONS_NAMES.RESTAURANTS),
          where('groupID', '==', userSnap?.data()?.groupID)
        );

        const restoSnap = await getDocs(restaurantRef);
        const items: any[] = [];
        restoSnap.forEach((el) => {
          items.push(el.data());
        });

        if (items[0]?.isArchived || items[0]?.isBlocked) {
          await signOut(auth);
          throw new Error(
            i18n.t(
              items[0]?.isArchived
                ? 'restaurant_is_archived'
                : 'restaurant_is_blocked'
            )
          );
        }

        // methode de paiement
        const paymentRef = query(
          collection(
            firestore,
            `${COLLECTIONS_NAMES.RESTAURANTS}/${items[0]?.id}/${COLLECTIONS_NAMES.paymentMethods}`
          )
        );
        const paymentSnap = await getDocs(paymentRef);
        const paymentArray: any[] = [];
        paymentSnap.forEach((el) => {
          paymentArray.push(el.data());
        });
        // console.log('paymentArray', paymentArray);
        const temp: any = {};
        for (const el of paymentArray) {
          temp[el.documentId] = el;
        }
        dispatch(
          initAuth({
            user: userSnap.data(),
            restaurant: items[0],
            paymentMethod: temp
          })
        );

        callback?.();
      } else {
        // notifyError('someting went wrong try again');
        throw new Error(i18n.t('someting_went_wrong_try_again'));
      }
    } catch (error) {
      onError?.(error);
    } finally {
      dispatch(authIsLoaded(false));
    }
  };

export const initRestaurantAsync =
  ({
    data,
    callback,
    onError
  }: {
    data: any;
    callback?: () => void;
    onError?: (ev: any) => void;
  }) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(creatinRestaurant(true));

      const data_to_save: Restaurant = {
        id: data?.id,
        name: data?.restaurantName,
        createdAt: new Date(),
        updatedAt: new Date(),
        isActive: true,
        userID: v4(),
        description: '',
        email: data?.email,
        banners: [],
        phoneNumber: data?.mobileNumber,
        hours: {
          monday: {
            close: false,
            time: []
          },
          tuesday: {
            close: false,
            time: []
          },
          wednesday: {
            close: false,
            time: []
          },
          thursday: {
            close: false,
            time: []
          },
          friday: {
            close: false,
            time: []
          },
          saturday: {
            close: false,
            time: []
          },
          sunday: { close: true, time: [] }
        },
        tags: ['Chinese', 'Pizza'],
        placeInfo: data?.placeInfo,
        logoURL: '',
        location: {
          address: data?.businessLocation,
          ...data?.geoLocation
        },
        rating: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0
        }
      };

      await setDoc(
        doc(firestore, COLLECTIONS_NAMES.RESTAURANTS, data?.id),
        data_to_save
      );

      dispatch(initAuth(data_to_save));
    } catch (error) {
      onError?.(error);
      console.log('init restaurant error', error);
    } finally {
      callback?.();
      dispatch(creatinRestaurant(false));
    }
  };

export const updateRestaurantAsync =
  ({
    data,
    callback,
    onError,
    onStart
  }: {
    data: any;
    callback?: () => void;
    onError?: (ev: any) => void;
    onStart?: () => void;
  }) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      onStart?.();
      const data_to_save: Restaurant = {
        ...data
      };

      const newImages = data.banners?.filter(
        (banner: any) => typeof banner.image !== 'string'
      );
      const oldImages = data.banners?.filter(
        (banner: any) => typeof banner.image === 'string'
      );

      if (data?.logoURL && typeof data.logoURL !== 'string') {
        data_to_save.logoURL = await uploadImage({
          file: data.logoURL
        });
      }

      const docRef = doc(
        firestore,
        `${COLLECTIONS_NAMES.RESTAURANTS}/${data.id}`
      );

      if (newImages?.length) {
        const newBanners = [] as any;
        for (let i = 0; i < newImages.length; i++) {
          const res = await uploadImage({
            file: newImages[i].image
          });

          newBanners.push({ ...newImages[i], image: res });
        }
        data_to_save.banners = [...newBanners, ...oldImages];
      }

      await setDoc(docRef, data_to_save, { merge: true });
      dispatch(updateRestaurant(data_to_save));
      callback?.();
    } catch (error) {
      onError?.(error);
      console.log('update restaurant error', error);
    }
  };

export const updatePaymentAsync =
  ({
    data,
    callback,
    onError,
    onStart
  }: {
    data: any;
    callback?: () => void;
    onError?: (ev: any) => void;
    onStart?: () => void;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      onStart?.();
      const docRef = doc(
        firestore,
        `${COLLECTIONS_NAMES.RESTAURANTS}/${data.restaurantId}/${COLLECTIONS_NAMES.paymentMethods}/${data.documentId}`
      );

      delete data?.restaurantId;
      await setDoc(docRef, data, { merge: true });
      dispatch(createPayment(data));
      callback?.();
    } catch (error) {
      onError?.(error);
      console.log('update payment error', error);
    }
  };

export const deletePaymentAsync =
  ({
    data,
    callback,
    onError,
    onStart
  }: {
    data: any;
    callback?: () => void;
    onError?: (ev: any) => void;
    onStart?: () => void;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      onStart?.();
      const docRef = doc(
        firestore,
        `${COLLECTIONS_NAMES.RESTAURANTS}/${data.restaurantId}/${COLLECTIONS_NAMES.paymentMethods}/${data.documentId}`
      );
      delete data?.restaurantId;
      await deleteDoc(docRef);
      dispatch(deletePayment(data));
      callback?.();
    } catch (error) {
      onError?.(error);
      console.log('update payment error', error);
    }
  };

export const onLogout = () => async (dispatch: AppDispatch) => {
  try {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    signOut(auth);
    dispatch({ type: 'USER_LOGGED_OUT' });
  } catch (error) {
    console.log('logout error', error);
  }
};

export const markNotificationRead =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const user = getState().auth.restaurant;
      await setDoc(
        doc(firestore, COLLECTIONS_NAMES.RESTAURANTS + '/' + user.id),
        { lastReadNotification: new Date() },
        { merge: true }
      );
      dispatch(
        updateLastRead({ lastReadNotification: new Date(), id: user.id })
      );
    } catch (err) {
      console.log('error when read notifications', err);
    }
  };

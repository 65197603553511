/* eslint-disable no-unused-vars */

export interface localisation {
  lat: number;
  lng: number;
}
export interface MealItem {
  addons: any[];
  cancelled: boolean;
  complementID: string;
  complementImageUrl: string;
  complementName: string;
  complementPrice: number;
  extraID: string;
  extraName: string;
  extraPrice: number;
  extraImageUrl: string;
  id: string;
  image: string;
  mealID: string;
  name: string;
  price: number;
  quantity: number;
  restaurantID: string;
}
export interface MenuCategoryItem {
  name: { fr: string; en: string };
  id: string;
}

export interface CategoryItem {
  title: string;
  key: string;
  id: number;
  en?: string;
  fr?: string;
}

export interface Address {
  id?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  coord?: any;
  createdAt?: string;
  updateAt?: string;
  address?: string;
}

export interface PaymentMethod {
  id?: string;
  customerId?: string;
  isPrimary?: boolean;
  type?: string;
  phoneNumber?: string;
  accountName?: string;
  cardNumber?: string;
  expirationDate?: string;
  createdAt: string;
  updateAt: string;
}

export interface Review {
  id?: string;
  mealID?: string;
  customerID?: string;
  restaurantID?: string;
  description?: string;
  tags?: string[];
  rating: number;
  isActive?: boolean;
  createdAt: Date | number;
  updateAt: string;
  [key: string]: any;
}

export enum DriverDeliveryState {
  DELIVERY_ACCEPTED = 'DELIVERY_ACCEPTED',
  ORDER_PICKED = 'ORDER_PICKED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  DELIVERED = 'DELIVERED'
}

export interface Order {
  [x: string]: any;
  id?: string;
  restaurantID?: string;
  restaurantName?: string;
  driverID?: string;
  customerID?: string;
  customerName: string; //
  restaurantInfo: RestaurantInfo; //
  driverInfo: DriverInfo; //
  meals: Meal[]; // id, name, price, quantity, realPrice,
  numberOfItems: number;
  srcAdress: Address;
  destAdress: Address;
  promoCodeID?: string;
  promoCodeInfo: PromoCodeInfo;
  paymentInfo?: PaymentInfo;
  discount?: number;
  createdAt: Date | number;
  updatedAt: Date | number;
  deliveryFee: number;
  mgnFee: number;
  deliveredAt?: string;
  deliveryAddress?: any;
  cancelAt?: string;
  paymentMethod: any;
  total: number;
  items: any[];
  status:
    | 'DELIVERED'
    | 'CANCELLED'
    | 'NEW'
    | 'READY'
    | 'PREPARING'
    | 'ON_ROUTE';
  DriverInOrder: {
    driverId: string;
    driverName: string;
    restaurantName: string;
    driverDeliveryState: string;
  };
}

export interface Meal {
  id?: number;
  categoryID?: string;
  restaurantID?: string;
  name?: string;
  photoURL?: string;
  description?: string;
  tags?: string[];
  available?: boolean;
  createdAt?: string;
  updatedAt?: string;
  images?: string[];
}

export interface Customer {
  id?: number;
  firstname: string;
  lastname: string;
  name?: string;
  phoneNumber?: string;
  photoURL?: string;
  email?: string;
  favorite?: FavoriteInfo[];
  settings?: Settings;
  coords?: Address;
  createdAt?: number;
  updateAt?: number;
  order?: any;
  spent?: any;
  orderStat?: {
    totalOrder?: number;
    totalSpent?: number;
  };
}

export interface Driver {
  id?: number | string;
  phoneNumber: string;
  firstname: string;
  lastname: string;
  photoURL: string;
  createdAt?: number | Date;
  updatedAt?: number | Date;
  verifyAt?: number;
  coords?: Address;
  transportType: string;
  numberPlate: string;
  status?: 'BLOCKED' | 'ARCHIVED' | 'ACTIVE' | 'INCOMING';
  orderStat?: {
    totalOrder?: number;
    totalSpent?: number;
  };
  [key: string]: any;
}

export enum RIDER_STATUS {
  INCOMING = 'INCOMING',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE'
}

export interface Restaurant {
  id?: number;
  name?: string;
  description?: string;
  managerId?: string;
  location?: Address;
  logoURL?: string;
  images?: string[];
  openingHours?: { day?: string; hour?: string[] };
  isActive: boolean;
  createAt?: string;
  validateAt?: string;
  email: string;
  updatedAt?: string;
  meal?: Meal[];
  order?: Order[];
  phoneNumber: number;
  placeInfo: string[];
  authID: string;
  lastReadNotification?: any;
}

export interface CategoryId {
  id?: number;
  name?: string;
  createdAt?: string;
  updateAt?: string;
}

export interface PaymentInfo {
  amount?: number;
  createdAt?: string;
  paymentMethodId?: string;
}

interface FavoriteInfo {
  mealID: string;
  name: string;
  price: number;
  photoURL: string;
  description?: string;
}

interface Settings {
  langugage?: string;
  theme?: string;
  bySms: boolean;
  byEmail: boolean;
}

interface CustomerInfo {
  name: string;
  phoneNumber: string;
}

interface RestaurantInfo {
  name: string;
  phoneNumber: string;
}

interface DriverInfo {
  name: string;
  phoneNumber: string;
  driverCustomerTime?: any;
  driverCustomerDis?: string | number;
  [key: string]: any;
}

interface PromoCodeInfo {
  code: string;
  createdAt: string;
  expiredAt: string;
  updateAt?: string;
}

export const ADMIN_NOTIFICATIONS_STATUS = {
  CANCELLED_BY_RESTO: 'CANCELLED_BY_RESTO',
  CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
  CANCELLED_MEAL: 'CANCELLED_MEAL',
  CANCELLED_DY_DRIVER: 'CANCELLED_DY_DRIVER',
  CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  NEW_ORDER: 'NEW_ORDER'
};
